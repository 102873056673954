import React from "react";

export const Header = () => {
  return (
    <header id="header">
      <div id="home" className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12 intro-text">
                <div className="intro-text-content">
                  <h1>
                    <small>Welcome to </small>Dotted Line
                  </h1>
                  <p>Streamlining your procurement processes</p>
                  <a
                    href="#about"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Learn More &#x2193;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
