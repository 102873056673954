import React from "react";

export const Banner = () => {
  return (
    <div id="signup-banner" className="signup-banner">
      <div className="container">
        <div className="banner-content">
          <span>🚀 Discover Framework Finder - For Free!</span>
          <a href="https://dash.dottedl.com/signup" className="banner-button page-scroll" target="_blank" rel="noopener noreferrer">
            Sign Up!
          </a>
        </div>
      </div>
    </div>
  );
}; 