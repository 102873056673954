import React, { useState } from "react";

export const Features = () => {
  const [activeFeature, setActiveFeature] = useState('procurement');

  const features = {
    procurement: {
      icon: 'fa-cogs',
      title: 'e-Sourcing and Procurement Automation',
      subtitle: 'Streamline your Procurement Process with Automation',
      description: 'The Procurement module within Dotted Line unlocks a powerful suite of automation tools designed to enhance procurement workflows. Seamlessly integrated with core project management functionalities, these tools save time and ensure precision in every stage of your procurement process.',
      capabilities: [{
          title: 'Centralised Project Information',
          description: 'Gain a comprehensive view of your project\'s key details through a central hub that ensures all essential project information is easily accessible, effortlessly updated, and effectively managed.'
        },
        {
          title: 'Research and Knowledge Repository',
          description: 'Project files uploaded to Research and Knowledge form a knowledge base, seamlessly feeding into procurement automation tools by pre-populating relevant information for user review and refinement.'
        },
        {
          title: 'Tender Pack Automation',
          description: 'Generate tender packs effortlessly with key details pre-populated from your project information.'
        },
        {
          title: 'Evaluation Matrix Generation',
          description: 'Simply enter bidder names to generate a structured, formatted evaluation matrix ready for distribution.'
        },
        {
          title: 'Moderation Matrix Generation',
          description: 'Upload evaluation scores to generate a ranked moderation matrix with pre-populated justifications.'
        },
        {
          title: 'Outcome Letter Generation',
          description: 'With support for non-standard layouts, the outcome letter automation tool produces compliant outcome letters directly from the final moderation matrix.'
        }
      ],
    },
    framework: {
      icon: 'fa-search',
      title: 'Framework Finder',
      subtitle: 'Quick Access to NHS-Approved Procurement Frameworks',
      description: 'Dotted Line\'s Framework Finder is a powerful platform designed to help users easily identify the most suitable NHS-approved procurement frameworks for their projects. With a focus on ease of use and efficiency, it provides access to up-to-date information across a wide range of frameworks, hosts, and suppliers.',
      actions: [
        {
          title: 'Sign Up!',
          link: 'https://dash.dottedl.com/signup',
        },
      ],
      capabilities: [{
        title: 'Detailed Framework Information',
        description: 'Get access to comprehensive, up-to-date information on NHS-approved frameworks, including framework duration, benefits and available lots.'
      },
      {
        title: 'Intuitive Search and Filtering Options',
        description: 'Narrow your search with our AI-powered search tool and framework category filters.'
      }]
    },
    project: {
      icon: 'fa-check-circle',
      title: 'Project Management',
      subtitle: 'Efficient Collaboration for Every Project',
      description: 'The Project Management functionality in Dotted Line simplifies managing projects through a centralised platform that encourages collaboration and oversight. Whether you\'re handling small tasks or large-scale initiatives, Dotted Line ensures that every project stays organised and on track.',
      capabilities: [
        {
          title: 'Collaboration and Project Management',
          description: 'A platform where workspaces, teams, projects, and tasks are managed, providing a shared environment for collaboration and oversight across multiple initiatives.'
        },
        {
          title: 'Task Management',
          description: 'Easily create, assign, and track tasks within projects with real-time status updates.'
        },
        {
          title: 'Reporting',
          description: 'Dotted Line\'s reporting capabilities provide oversight of project and team performance.'
        },
        {
          title: 'File Management',
          description: 'Securely store and manage project documents and files.'
        }
      ]
    },
    contract: {
      icon: 'fa-briefcase',
      title: 'Contract Management',
      isComingSoon: true,
      subtitle: 'Effortless Contract Oversight',
      description: 'Dotted Line is expanding its features to include comprehensive contract management capabilities, designed to support the complex needs of NHS procurement and contract oversight. This new functionality will seamlessly integrate with your project workflows, providing a structured and scalable way to manage contracts across multiple authorities, departments, and supplier relationships.',
      capabilities: []
    }
  };

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Features</h2>
        </div>
        <div className="features-tabs">
          <div className="features-nav">
            {Object.entries(features).map(([key, feature]) => (
              <button
                key={key}
                className={`feature-tab ${activeFeature === key ? 'active' : ''}`}
                onClick={() => setActiveFeature(key)}
              >
                <i className={`fa ${feature.icon}`}></i>
                <span>{feature.title}</span>
              </button>
            ))}
          </div>
          <div className="feature-content">
            <div className="feature-header">
              <div className="feature-header-title">
                <h3>{features[activeFeature].subtitle}</h3>
                {features[activeFeature].isComingSoon && (
                  <span className="coming-soon-badge">Coming Soon</span>
                )}
              </div>
              
              <p className="feature-description">{features[activeFeature].description}</p>

              {features[activeFeature].actions && (
                <a href={features[activeFeature].actions[0].link} className="feature-action-button" target="_blank" rel="noopener noreferrer">
                  {features[activeFeature].actions[0].title}
                </a>
              )}
            </div>
            <div className="feature-details">
                {features[activeFeature].capabilities.map((capability, index) => (
                  <div key={index} className="feature-bullet-container">
                    <div className="feature-bullet-details">
                      <h5>{capability.title}</h5>
                      <p>{capability.description}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
