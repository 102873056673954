import React from "react";

export const About = (props) => {
  return (
    <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="about-text">
                <h2>About Us</h2>
                <p>Dotted Line is EcoVate's Software as a Service (SaaS) platform designed to streamline project management and procurement workflows. From initial planning to procurement execution and contract management, Dotted Line supports teams throughout the entire project life-cycle, providing structure, visibility, and seamless collaboration at every stage. By automating key processes and simplifying document generation, the platform reduces manual effort, ensures consistency, and enhances efficiency.</p>
                <p>Built with extensive expertise in procurement and project management, Dotted Line helps organisations work smarter, stay organised, and drive projects forward efficiently.</p>
                
                <div className="video-container">
                  <div className="responsive-video-wrapper">
                    <iframe 
                      width="560" 
                      height="315" 
                      src="https://www.youtube.com/embed/89VJ4yvGRr0?si=d9ifZaAHMN91B6Nw" 
                      title="YouTube video player" 
                      frameBorder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      referrerPolicy="strict-origin-when-cross-origin" 
                      allowFullScreen>
                    </iframe>
                  </div>
                </div>
                
                <div id="ecovate-container">
                  <h3>Partnership with EcoVate Group</h3>
                  <p>Dotted Line has proudly partnered with EcoVate Group, a leader in procurement consultancy, management and commercial expertise. Through our collaboration we aim to enhance procurement processes, delivering exceptional results and value to our clients.</p>
                  <div className="about-bullet-container">
                    <div className="about-bullet"></div>
                    <div className="about-bullet-details">
                      <h5>Streamlined Procurement Consultancy</h5>
                      <p>EcoVate utilises Dotted Line's advanced system capabilities to enhance procurement support. By integrating Dotted Line into its workflow, EcoVate ensures a seamless consultancy experience from start to finish, featuring streamlined communication, real-time progress tracking, and improved decision-making processes. Dotted Line's features help optimise operations, increasing efficiency and providing valuable insights for procurement consultancy and management.</p>
                    </div>
                  </div>
                  <div className="about-bullet-container">
                    <div className="about-bullet"></div>
                    <div className="about-bullet-details">
                      <h5>Enhanced User Experience with Expert Collaboration</h5>
                      <p>Dotted Line users benefit from EcoVate's expertise to elevate their procurement consultancy experience. With EcoVate's support, users receive specialised guidance on procurement strategies, regulatory compliance, and strategic decision-making. EcoVate's consultancy complements Dotted Line's robust system capabilities, enriching the overall user experience and ensuring procurement success.</p>
                    </div>
                  </div>
                  <div id="ecovate-logo-container">
                    <a
                      href="https://ecovategroup.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-custom btn-lg ecovate-btn"
                    >
                      Explore EcoVate Group
                    </a>
                    <a
                      href="https://ecovategroup.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../img/logo_ecovate.png" alt="EcoVate Group Logo" style={{ width: '150px'}}/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
